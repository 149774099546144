<script setup lang="ts">
import type { ProductsProps } from "./Products.props"

defineProps<ProductsProps>()

const emit =
  defineEmits<{
    (e: "onAddToCart", productName: string): void
    (e: "onClickShowResult"): void
  }>()

const clickShowResult = () => emit("onClickShowResult")

const handleAddToCartNotification = (productName: string) => {
  emit("onAddToCart", productName)
}

const searchInput = useState("searchbar-query-state")
</script>

<template>
  <div class="search-products">
    <div class="beaver mb-6 flex items-center gap-1" v-if="suggestion">
      <span>{{ $t("searchbar.maybeYouLookingFor") }}:</span>
      <UtilsMarkdown
        class="
          markdown__link-beaver-medium
          markdown__link-underlined
          markdown__link-green-main
        "
        :content="suggestion"
      />
    </div>

    <div class="relative grid md:grid-cols-8 md:gap-x-3 md:gap-y-3">
      <div
        v-if="moreProducts"
        :class="[
          'order-first flex h-full w-full items-end md:absolute md:col-span-4 md:col-start-5 md:h-auto',
          { 'md:-top-[67px]': cards?.length > 1 },
          { 'md:bottom-0': cards?.length == 1 }
        ]"
      >
        <UtilsButton
          class="
            beaver-medium
            w-full
            [&_span.utils-markdown]:text-center [&_span]:whitespace-normal
          "
          v-bind="moreProducts"
          theme="outlined-green"
          order="last"
          @click="clickShowResult"
          stop
        >
          <template #icon
            ><UtilsIcon
              name="ArrowRight.svg"
              color="green-main"
              class="h-5 w-5"
          /></template>
        </UtilsButton>
      </div>
      <div
        class="mt-2 md:col-span-4 md:mt-0"
        v-for="(card, index) in cards"
        :key="card.title"
      >
        <GAListInstantSearchProducts :name="searchInput">
          <GAItem id="search-results" :item="cards" :disable="index != 0">
            <GAViewItemList method="ricerca">
              <ProductTileOverallSearch
                @notification="handleAddToCartNotification(card.title)"
                v-bind="card"
                class="md:h-full"
                is-search-results
                :full-width="false"
                :item-list="`Instant Search: ${searchInput}`"
              />
            </GAViewItemList>
          </GAItem>
        </GAListInstantSearchProducts>
      </div>
    </div>
  </div>
</template>
